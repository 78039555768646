var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{attrs:{"tag":"div","vid":_vm.vid,"name":_vm.$attrs.name || _vm.label,"rules":_vm.rules,"mode":_vm.validationMode},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',_vm._b({key:!!_vm.prefix || !!_vm.suffix,attrs:{"label":_vm.label,"disabled":_vm.disabled,"addons":!!_vm.prefix || !!_vm.suffix,"type":{
      'is-default': _vm.disabled,
      'is-danger': errors[0] || _vm.isError,
      'is-info': _vm.type == 'is-info',
      'is-success': _vm.type == 'is-success'
    },"message":_vm.hideDetails ? null : errors.concat( _vm.message)}},'b-field',_vm.$attrs,false),[(_vm.prefix)?_c('p',{staticClass:"control"},[_c('span',{staticClass:"button is-static",class:_vm.size},[_vm._v(_vm._s(_vm.prefix))])]):_vm._e(),_c('b-input',_vm._b({ref:_vm.inputRef,attrs:{"expanded":_vm.expanded,"tabindex":_vm.tabindex,"placeholder":_vm.placeholder,"size":_vm.size,"disabled":_vm.disabled,"type":_vm.inputType,"step":_vm.step,"has-counter":_vm.hasCounter},on:{"input":_vm.emitInput,"icon-right-click":_vm.emitIconRightClick,"blur":_vm.emitBlur,"focus":_vm.emitFocus},nativeOn:{"change":function($event){return _vm.emitChange($event)}},model:{value:(_vm.innerValue),callback:function ($$v) {_vm.innerValue=$$v},expression:"innerValue"}},'b-input',_vm.$attrs,false)),(_vm.suffix)?_c('p',{staticClass:"control"},[_vm._t("suffix",[_c('span',{staticClass:"button is-static",class:_vm.size},[_vm._v(_vm._s(_vm.suffix))])])],2):_vm._e()],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }